import React, { useState } from 'react';
import { Link } from 'gatsby';
import logo from '../images/logo_white.svg';
import instagram from '../images/instagram.svg';
import linkedin from '../images/linkedin.svg';
import email from '../images/email.svg';
import twitter from '../images/icon_twitter.svg';

const Header = () => {
  const [menu, setMenu] = useState(false);
  function handleClick(e) {
    e.preventDefault();
    setMenu(!menu);
  }

  return (
    <>
      <header className={`${menu ? 'is_active' : ''}`}>
        <button
          type="button"
          className={`menu_toggle ${menu ? 'is_active' : ''}`}
          onClick={handleClick}
        >
          <span></span>
        </button>
        <div className="nav_desktop">
          <div className="container">
            <nav>
              <div className="nav_grid">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <div className={`name ${menu ? 'is_active' : ''}`}>
                  <h3>Andreja Mahović</h3>
                </div>
                <nav className={`navigation ${menu ? 'is_active' : ''}`}>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-me/">About Me</Link>
                    </li>
                    <li>
                      <Link to="/about-me/#contact">Contact</Link>
                    </li>
                  </ul>
                </nav>
                <div className={`socials ${menu ? 'is_active' : ''}`}>
                  <ul>
                    <li className="instagram">
                      <a
                        href="https://www.instagram.com/l99dre/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={instagram} alt="instagram icon" />
                      </a>
                    </li>
                    <li className="linkedin">
                      <a
                        href="https://rs.linkedin.com/in/andreja-mahovi%C4%87-967bb756"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={linkedin} alt="linkedin icon" />
                      </a>
                    </li>
                    <li className="email">
                      <a href="mailto:a@tier-s.co">
                        <img src={email} alt="email icon" />
                      </a>
                    </li>
                    <li className="twitter">
                      <a
                        href="https://twitter.com/pmandreja"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={twitter} alt="twitter icon" />
                      </a>
                    </li>
                    {/* <li className="discord">
											<a href="/">
												<img src={discord} alt="discord icon" />
											</a>
										</li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="container-mobile">
          <nav>
            <div className="nav_mobile_grid">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>

              <div className={`nav_mobile ${menu ? 'is_active' : ''}`}>
                <div className="content_wrapper">
                  <div className={`name ${menu ? 'is_active' : ''}`}>
                    <h3>Andreja Mahovic</h3>
                  </div>
                  <nav className="navigation">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-me/">About Me</Link>
                      </li>
                      <li>
                        <Link to="/about-me/#contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                  <div className={`socials ${menu ? 'is_active' : ''}`}>
                    <ul>
                      <li className="instagram">
                        <a
                          href="https://www.instagram.com/l99dre/?hl=en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={instagram} alt="instagram icon" />
                        </a>
                      </li>
                      <li className="linkedin">
                        <a
                          href="https://rs.linkedin.com/in/andreja-mahovi%C4%87-967bb756"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={linkedin} alt="linkedin icon" />
                        </a>
                      </li>
                      <li className="email">
                        <a href="mailto:a@tier-s.com">
                          <img src={email} alt="email icon" />
                        </a>
                      </li>
                      <li className="twitter">
                        <a
                          href="https://twitter.com/pmandreja"
                          rel="noreferrer"
                        >
                          <img src={twitter} alt="twitter icon" />
                        </a>
                      </li>
                      {/* <li className="discord">
												<a href="/">
													<img src={discord} alt="discord icon" />
												</a>
											</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
