/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import footerArrow from '../images/arrows_footer.svg';
import ScrollToTop from './Scroll_to_top';

import { AllCategories } from '../hooks/useAllCategories';

const Footer = () => {
	const [select, setSelect] = useState(false);
	function handleClick() {
		setSelect(!select);
	}
	const date = new Date();
	const year = date.getFullYear();
	const footerCats = AllCategories();

	return (
		<>
			<footer>
				<div className="container">
					<div className="footer_grid">
						<div className="copyrights">
							<p>&#169; {year} Andreja Mahović</p>
						</div>

						<div onClick={handleClick} className="select">
							<p>
								Archive{' '}
								<span>
									<img src={footerArrow} alt="footer_arrows" />
								</span>
							</p>
							<ul className={select ? 'selected' : ''}>
								{footerCats.edges.map(cat => (
									<Link to={`/category/${cat.node.slug}`} key={cat.node.name}>
										<li className="option">
											<div dangerouslySetInnerHTML={{ __html: cat.node.name }} />
										</li>
									</Link>
								))}
							</ul>
						</div>

						<div className="developed">
							<p>
								Designed and Developed by:{' '}
								<a href="https://singularminds.eu/" target="_blank" rel="noreferrer">
									SingularMinds
								</a>
							</p>
						</div>
					</div>
				</div>
				<ScrollToTop />
			</footer>
		</>
	);
};

export default Footer;
