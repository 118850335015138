import { useStaticQuery, graphql } from 'gatsby';

export const AllCategories = () => {
	const HomeCats = useStaticQuery(
		graphql`
			query GET_ALL_CATEGORIES {
				allWordpressCategory(filter: { count: { gt: 0 } }) {
					edges {
						node {
							id
							name
							slug
						}
					}
				}
			}
		`
	);
	return HomeCats.allWordpressCategory;
};
