import React, { useState, useEffect } from 'react';
// import ArrowLeft from '../images/icon_arrow_left.svg';
import ArrowTop from '../images/arrow_top_new.png';

const ScrollToTop = () => {
	const [showScroll, setShowScroll] = useState(false);
	if (typeof window !== 'undefined') {
		require('smooth-scroll')('a[href*="#"]');
	}

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		const checkScrollTop = () => {
			if (!showScroll && window.pageYOffset > 400) {
				setShowScroll(true);
			} else if (showScroll && window.pageYOffset <= 400) {
				setShowScroll(false);
			}
		};
		window.addEventListener('scroll', checkScrollTop);
	}, [showScroll]);

	return (
		<>
			<button
				type="button"
				className="scroll_button"
				onClick={scrollTop}
				style={{ right: showScroll ? '20px' : '-300px' }}
			>
				<div className="scroll_top">
					<img src={ArrowTop} alt="scroll to top" />
				</div>
			</button>
		</>
	);
};

export default ScrollToTop;
